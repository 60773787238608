import React, { ReactElement } from "react";
import MainBanner from "@components/topcamplejeunelawyers/mainBanner";
import TopCampLejeuneLawyersWrapper from "@components/layouts/topCampLejeuneLawyersWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";

import { useDomainContext } from "@hooks/useDomainContext";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const Case = dynamic(() => import("@components/topcamplejeunelawyers/case"));
const WhyUs = dynamic(() => import("@components/topcamplejeunelawyers/whyUs"));
const ToxicWaste = dynamic(
    () => import("@components/topcamplejeunelawyers/toxicWaste"),
);
const Lawsuit = dynamic(
    () => import("@components/topcamplejeunelawyers/lawsuit"),
);

export default function CampLejeuneApplicationHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <TopCampLejeuneLawyersWrapper>
            <MainBanner />

            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Lawsuit />
            <WhyUs />
            <ToxicWaste />
            <Case />
        </TopCampLejeuneLawyersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
